<template>
  <div class="upload-documents-vue">
    <Header class="responsive-bar">
      <template v-slot:title>
        {{ $t("upload-documents-7773dfbb1710dccc7b95283d157c8bc6") }}
      </template>
    </Header>
    <div v-if="whitelabel === 'VCGMarkets'" id="sumsub-websdk-container"></div>

    <div>
      <div
        class="upload-document max-w-3xl m-auto bg-white shadow rounded-md sm:mt-5"
      >
        <div class="pb-5 border-b border-gray-200">
          <div class="flex justify-between">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              {{ $t("upload-documents-7773dfbb1710dccc7b95283d157c8bc6") }}
            </h3>
          </div>
          <p class="mt-1 text-sm leading-5 text-gray-500">
            <span v-if="whitelabel === 'Blackstone'">
              Please provide us with the following documents to Verify Your
              Account.
            </span>
            <span v-else>
              {{ $t("please-provide-us-with-the-following-documents") }}
            </span>
            <br />
            {{
              $t(
                "files-should-be-in-pdf-jpg-or-png-format-and-must-not-exceed-5mb"
              )
            }}
            <br />
            <span v-if="whitelabel === 'Blackstone'">
              PLEASE NOTE: Once Document is selected it is Uploaded
              Automatically.
            </span>
            <span
              v-if="
                whitelabel !== 'TD365' &&
                whitelabel !== 'Blackstone' &&
                whitelabel !== 'OneTradingMarkets'
              "
            >
              {{
                $t(
                  "when-all-your-required-application-documents-have-been-submitted-they-will-instantly-be-reviewed-by-"
                )
              }}
            </span>
          </p>
        </div>
        <div class="pt-4">
          <div v-if="errorSize.value">
            <span class="text-sm text-red-600 h-4">{{
              $t(errorSize.message).replace(": .", ": 5bm.")
            }}</span>
          </div>
          <div v-if="errorType.value">
            <span class="text-sm text-red-600 h-4">{{
              $t(errorType.message)
            }}</span>
          </div>
          <div
            v-for="(item, index) in get_upload_presets"
            :key="index"
            :class="
              index === get_upload_presets.length - 1
                ? 'sm:mb-5 '
                : 'border-b border-gray-300'
            "
            class="sm:items-start sm:pt-5 pb-10"
          >
            <label
              :for="item.id"
              class="block text-md leading-5 font-medium text-gray-700 sm:mt-px sm:pt-2 mb-5 mt-5"
            >
              <span
                v-if="item.name === 'ID Proof' && whitelabel === 'OrbitInvest'"
              >
                Proof of ID - Front (Required)
              </span>
              <span v-else-if="item.name === 'ID Proof'">
                {{ $t("proof-of-id-required") }}
              </span>
              <span v-else-if="item.name === 'ID Proof Back'">
                {{ $t("proof-of-id-back-required") }}
              </span>
              <span v-else-if="item.name === 'Address Proof'">
                {{ $t("proof-of-address-required") }}
              </span>
              <span v-else-if="item.name === 'Additional Customer Document 1'">
                {{ $t("additional-customer-document-1") }}
              </span>
              <span v-else-if="item.name === 'Additional Customer Document 2'">
                {{ $t("additional-customer-document-2") }}
              </span>
              <span v-else>
                {{ item.name }}
              </span>
            </label>
            <div class="sm:grid sm:grid-cols-5 sm:grid-flow-col gap-4">
              <div class="mt-2 sm:mt-0 col-span-3 mr-3">
                <div
                  class="box flex justify-center p-3 border-2 border-gray-300 border-dashed rounded-md"
                >
                  <div
                    v-if="
                      get_uploaded_documents.results &&
                      get_uploaded_documents.results.find(
                        (document) => document.document_type_id === item.id
                      )
                    "
                    class="uploaded-file"
                  >
                    <div class="text-center mt-8 relative">
                      <svg
                        class="mx-auto"
                        fill="none"
                        height="38"
                        viewBox="0 0 38 38"
                        width="38"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0)">
                          <path
                            d="M11.8752 19.7916C13.6241 19.7916 15.0418 18.3738 15.0418 16.6249C15.0418 14.876 13.6241 13.4583 11.8752 13.4583C10.1263 13.4583 8.7085 14.876 8.7085 16.6249C8.7085 18.3738 10.1263 19.7916 11.8752 19.7916Z"
                            stroke="#17BC4F"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.6"
                          />
                          <path
                            d="M11.8751 22.1666C10.1334 22.325 8.39175 22.8 6.80842 23.75C6.01675 24.225 5.54175 25.175 5.54175 26.2833V26.7583C5.54175 27.2333 5.85841 27.7083 6.49175 27.8666H17.4167C17.8917 27.8666 18.3667 27.3916 18.3667 26.7583V26.2833C18.3667 25.3333 17.8917 24.3833 17.1001 23.75C15.3584 22.8 13.6167 22.325 11.8751 22.1666Z"
                            stroke="#17BC4F"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.6"
                          />
                          <path
                            d="M22.9585 24.5416H32.4585"
                            stroke="#17BC4F"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.6"
                          />
                          <path
                            d="M32.4584 19.7916H19.7917"
                            stroke="#17BC4F"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.6"
                          />
                          <path
                            d="M16.6251 8.70825H3.16675C1.90008 8.70825 0.791748 9.81659 0.791748 11.0833V30.0833C0.791748 31.3499 1.90008 32.4583 3.16675 32.4583H34.8334C36.1001 32.4583 37.2084 31.3499 37.2084 30.0833V11.0833C37.2084 9.81659 36.1001 8.70825 34.8334 8.70825H21.3751"
                            stroke="#17BC4F"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.6"
                          />
                          <path
                            d="M19 5.54163C20.2667 5.54163 21.375 6.64996 21.375 7.91663V11.875H16.625V7.91663C16.625 6.64996 17.7333 5.54163 19 5.54163Z"
                            stroke="#17BC4F"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.6"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0">
                            <rect fill="white" height="38" width="38" />
                          </clipPath>
                        </defs>
                      </svg>
                      <div class="text-gray-500 text-sm font-normal mt-2">
                        {{
                          get_uploaded_documents.results.filter(
                            (document) => document.document_type_id === item.id
                          )[0].file_name
                        }}
                      </div>
                      <div class="text-gray-400 text-sm font-light">
                        {{ $t("document-uploaded-at") }}:
                        {{
                          get_uploaded_documents.results.filter(
                            (document) => document.document_type_id === item.id
                          )[0].uploaded_at | moment("MM-DD-YYYY, h:mm:ss A")
                        }}
                      </div>
                      <br />
                      <div
                        v-if="get_profile.state !== 'Full'"
                        class="cursor-pointer absolute -top-0 right-0"
                        @click="removeFile(item.id)"
                      >
                        <svg
                          fill="none"
                          height="31"
                          viewBox="0 0 31 31"
                          width="31"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g filter="url(#filter0_d)">
                            <rect
                              fill="white"
                              height="27"
                              rx="6"
                              width="27"
                              x="2"
                              y="1"
                            />
                            <rect
                              height="26"
                              rx="5.5"
                              stroke="#D2D6DC"
                              width="26"
                              x="2.5"
                              y="1.5"
                            />
                          </g>
                          <path
                            d="M11 11.6667H21M20.375 11.6667L19.8331 19.7613C19.8107 20.0977 19.6696 20.4125 19.4382 20.6424C19.2068 20.8722 18.9024 21 18.5863 21H13.4138C13.0976 21 12.7932 20.8722 12.5618 20.6424C12.3304 20.4125 12.1893 20.0977 12.1669 19.7613L11.625 11.6667H20.375ZM14.75 14.3333V18.3333V14.3333ZM17.25 14.3333V18.3333V14.3333ZM17.875 11.6667V9.66667C17.875 9.48986 17.8092 9.32029 17.6919 9.19526C17.5747 9.07024 17.4158 9 17.25 9H14.75C14.5842 9 14.4253 9.07024 14.3081 9.19526C14.1908 9.32029 14.125 9.48986 14.125 9.66667V11.6667H17.875Z"
                            stroke="#9FA6B2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <defs>
                            <filter
                              id="filter0_d"
                              color-interpolation-filters="sRGB"
                              filterUnits="userSpaceOnUse"
                              height="31"
                              width="31"
                              x="0"
                              y="0"
                            >
                              <feFlood
                                flood-opacity="0"
                                result="BackgroundImageFix"
                              />
                              <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                              />
                              <feOffset dy="1" />
                              <feGaussianBlur stdDeviation="1" />
                              <feColorMatrix
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
                              />
                              <feBlend
                                in2="BackgroundImageFix"
                                mode="normal"
                                result="effect1_dropShadow"
                              />
                              <feBlend
                                in="SourceGraphic"
                                in2="effect1_dropShadow"
                                mode="normal"
                                result="shape"
                              />
                            </filter>
                          </defs>
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div
                    v-else
                    class="text-center flex"
                    @drop="onFileChanged($event, item, true)"
                    @dragover.prevent
                  >
                    <div
                      v-if="item.is_two_sided && file_two_side.length === 1"
                      class="border-r border-gray-300 w-50 relative pr-3"
                    >
                      <div class="uploaded-file">
                        <div class="text-center pt-8">
                          <svg
                            class="mx-auto"
                            fill="none"
                            height="38"
                            viewBox="0 0 38 38"
                            width="38"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0)">
                              <path
                                d="M11.8752 19.7916C13.6241 19.7916 15.0418 18.3738 15.0418 16.6249C15.0418 14.876 13.6241 13.4583 11.8752 13.4583C10.1263 13.4583 8.7085 14.876 8.7085 16.6249C8.7085 18.3738 10.1263 19.7916 11.8752 19.7916Z"
                                stroke="#17BC4F"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.6"
                              />
                              <path
                                d="M11.8751 22.1666C10.1334 22.325 8.39175 22.8 6.80842 23.75C6.01675 24.225 5.54175 25.175 5.54175 26.2833V26.7583C5.54175 27.2333 5.85841 27.7083 6.49175 27.8666H17.4167C17.8917 27.8666 18.3667 27.3916 18.3667 26.7583V26.2833C18.3667 25.3333 17.8917 24.3833 17.1001 23.75C15.3584 22.8 13.6167 22.325 11.8751 22.1666Z"
                                stroke="#17BC4F"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.6"
                              />
                              <path
                                d="M22.9585 24.5416H32.4585"
                                stroke="#17BC4F"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.6"
                              />
                              <path
                                d="M32.4584 19.7916H19.7917"
                                stroke="#17BC4F"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.6"
                              />
                              <path
                                d="M16.6251 8.70825H3.16675C1.90008 8.70825 0.791748 9.81659 0.791748 11.0833V30.0833C0.791748 31.3499 1.90008 32.4583 3.16675 32.4583H34.8334C36.1001 32.4583 37.2084 31.3499 37.2084 30.0833V11.0833C37.2084 9.81659 36.1001 8.70825 34.8334 8.70825H21.3751"
                                stroke="#17BC4F"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.6"
                              />
                              <path
                                d="M19 5.54163C20.2667 5.54163 21.375 6.64996 21.375 7.91663V11.875H16.625V7.91663C16.625 6.64996 17.7333 5.54163 19 5.54163Z"
                                stroke="#17BC4F"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.6"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0">
                                <rect fill="white" height="38" width="38" />
                              </clipPath>
                            </defs>
                          </svg>
                          <div class="text-gray-500 text-sm font-normal mt-2">
                            {{ $t("upload-documents-first-side") }}
                          </div>
                          <div class="text-gray-400 text-sm font-light">
                            {{ $t("document-uploaded") }}
                          </div>
                          <br />
                          <div
                            v-if="get_profile.state !== 'Full'"
                            class="cursor-pointer absolute top-1 right-4"
                            @click="removeFile(item.id)"
                          >
                            <svg
                              fill="none"
                              height="31"
                              viewBox="0 0 31 31"
                              width="31"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g filter="url(#filter0_d)">
                                <rect
                                  fill="white"
                                  height="27"
                                  rx="6"
                                  width="27"
                                  x="2"
                                  y="1"
                                />
                                <rect
                                  height="26"
                                  rx="5.5"
                                  stroke="#D2D6DC"
                                  width="26"
                                  x="2.5"
                                  y="1.5"
                                />
                              </g>
                              <path
                                d="M11 11.6667H21M20.375 11.6667L19.8331 19.7613C19.8107 20.0977 19.6696 20.4125 19.4382 20.6424C19.2068 20.8722 18.9024 21 18.5863 21H13.4138C13.0976 21 12.7932 20.8722 12.5618 20.6424C12.3304 20.4125 12.1893 20.0977 12.1669 19.7613L11.625 11.6667H20.375ZM14.75 14.3333V18.3333V14.3333ZM17.25 14.3333V18.3333V14.3333ZM17.875 11.6667V9.66667C17.875 9.48986 17.8092 9.32029 17.6919 9.19526C17.5747 9.07024 17.4158 9 17.25 9H14.75C14.5842 9 14.4253 9.07024 14.3081 9.19526C14.1908 9.32029 14.125 9.48986 14.125 9.66667V11.6667H17.875Z"
                                stroke="#9FA6B2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <defs>
                                <filter
                                  id="filter0_d"
                                  color-interpolation-filters="sRGB"
                                  filterUnits="userSpaceOnUse"
                                  height="31"
                                  width="31"
                                  x="0"
                                  y="0"
                                >
                                  <feFlood
                                    flood-opacity="0"
                                    result="BackgroundImageFix"
                                  />
                                  <feColorMatrix
                                    in="SourceAlpha"
                                    type="matrix"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                  />
                                  <feOffset dy="1" />
                                  <feGaussianBlur stdDeviation="1" />
                                  <feColorMatrix
                                    type="matrix"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
                                  />
                                  <feBlend
                                    in2="BackgroundImageFix"
                                    mode="normal"
                                    result="effect1_dropShadow"
                                  />
                                  <feBlend
                                    in="SourceGraphic"
                                    in2="effect1_dropShadow"
                                    mode="normal"
                                    result="shape"
                                  />
                                </filter>
                              </defs>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="m-auto w-28">
                      <svg
                        class="mx-auto h-12 text-gray-400"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 48 48"
                      >
                        <path
                          d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                        />
                      </svg>
                      <p class="mt-1 text-sm text-gray-600">
                        <label
                          :for="item.id"
                          class="font-medium text-indigo-600 transition duration-150 ease-in-out"
                          >{{ $t("Upload") }}</label
                        >
                        <input
                          :id="item.id"
                          :multiple="item.is_two_sided"
                          accept="image/png, image/jpeg, application/pdf"
                          class="hidden"
                          type="file"
                          @change="onFileChanged($event, item, false)"
                        />
                        <span>
                          <span
                            v-if="
                              item.is_two_sided && file_two_side.length === 1
                            "
                          >
                            {{ $t("upload-documents-second-side") }}
                          </span>
                          <span v-else>
                            {{ $t("a-file") }}
                          </span>
                          <br />
                          {{ $t("or-drag-and-drop") }}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="text-gray-500 text-sm leading-5 font-normal mt-3">
                  <span
                    v-if="item.subtitle"
                    v-html="sanitizeText(item.subtitle)"
                  ></span>
                  <span
                    v-else-if="
                      item.name === 'Additional Customer Document 1' ||
                      item.name === 'Additional Customer Document 2' ||
                      item.name === 'Address Proof'
                    "
                  >
                    <span
                      v-if="
                        (whitelabel === 'ICMVC' ||
                          whitelabel === 'ICMMU' ||
                          whitelabel === 'ICMTrader' ||
                          whitelabel === 'GCCBrokers') &&
                        item.name === 'Address Proof'
                      "
                      class="text-gray-500 text-sm leading-5 font-normal mt-3"
                    >
                      {{
                        $t(
                          "please-upload-a-colour-copy-of-your-bank-statement-utility-bill-or-residence-permit"
                        )
                      }}
                    </span>
                    <span
                      v-else-if="
                        (whitelabel === 'ICMVC' ||
                          whitelabel === 'ICMAP' ||
                          whitelabel === 'ICMTrader' ||
                          whitelabel === 'ICMMU') &&
                        item.name === 'Additional Customer Document 1'
                      "
                      class="text-gray-500 text-sm leading-5 font-normal mt-3"
                    >
                      {{ $t("please-upload-any-additional-documents-here") }}
                    </span>
                    <span
                      v-else-if="
                        (whitelabel === 'ICMVC' ||
                          whitelabel === 'ICMAP' ||
                          whitelabel === 'ICMTrader' ||
                          whitelabel === 'ICMMU') &&
                        item.name === 'Additional Customer Document 2'
                      "
                      class="text-gray-500 text-sm leading-5 font-normal mt-3"
                    >
                      {{ $t("please-upload-any-additional-documents-here") }}
                    </span>
                    <span v-else>
                      {{
                        $t(
                          "please-upload-a-colour-copy-of-your-current-passport-driver-s-license-or-national-id"
                        )
                      }}
                    </span>
                  </span>
                  <span v-else>
                    {{
                      $t(
                        "please-upload-a-colour-copy-of-your-current-passport-driver-s-license-or-national-id"
                      )
                    }}
                  </span>
                </div>
              </div>
              <div class="mt-2 sm:mt-0 col-span-2">
                <div
                  class="max-w-lg flex justify-center border-none text-gray-500"
                >
                  <div>
                    <div
                      v-if="
                        whitelabel === 'TD365' ||
                        whitelabel === 'OneTradingMarkets'
                      "
                      class="text-sm leading-5 px-6 pt-5 pb-6 bg-gray-100 rounded-md"
                    >
                      <h3 class="text-sm leading-5 font-normal mb-5">
                        {{
                          $t(
                            "documents-must-contain-all-of-the-following-details"
                          )
                        }}
                      </h3>
                      <ul>
                        <li>
                          {{
                            $t(
                              "your-full-name-df1fc96e5401396fe01e24363a9ec40d"
                            )
                          }}
                        </li>
                        <li>{{ $t("a-unique-id-number") }}</li>
                        <li>{{ $t("your-birthdate") }}</li>
                        <li>{{ $t("your-place-of-birth") }}</li>
                        <li>
                          {{
                            $t(
                              "a-visible-photo-e30b7286eb772633ab9e452d34ad29bd"
                            )
                          }}
                        </li>
                        <li>{{ $t("your-signature") }}</li>
                        <li>{{ $t("your-nationality") }}</li>
                      </ul>
                    </div>
                    <div
                      v-else-if="item.name === 'ID Proof'"
                      class="text-sm leading-5 px-6 pt-5 pb-6 bg-gray-100 rounded-md"
                    >
                      <h3 class="text-sm leading-5 font-normal mb-5">
                        {{
                          $t(
                            "documents-must-contain-all-of-the-following-details"
                          )
                        }}
                      </h3>
                      <ul>
                        <li>
                          {{
                            $t(
                              "your-full-name-df1fc96e5401396fe01e24363a9ec40d"
                            )
                          }}
                        </li>
                        <li>{{ $t("your-birthdate") }}</li>
                        <li>{{ $t("expiry-date") }}</li>
                        <li>
                          {{
                            $t(
                              "a-visible-photo-e30b7286eb772633ab9e452d34ad29bd"
                            )
                          }}
                        </li>
                        <li>
                          {{
                            $t(
                              "all-four-corners-of-the-document-must-be-visible"
                            )
                          }}
                        </li>
                      </ul>
                    </div>
                    <div
                      v-else-if="
                        item.name === 'ID Proof Back' &&
                        whitelabel !== 'ICMMU' &&
                        whitelabel !== 'ICMVC'
                      "
                      class="text-sm leading-5 px-6 pt-5 pb-6 bg-gray-100 rounded-md"
                    >
                      <h3 class="text-sm leading-5 font-normal mb-5">
                        {{
                          $t(
                            "documents-must-contain-all-of-the-following-details"
                          )
                        }}
                      </h3>
                      <ul>
                        <li>
                          {{
                            $t(
                              "your-full-name-df1fc96e5401396fe01e24363a9ec40d"
                            )
                          }}
                        </li>
                        <li>{{ $t("your-birthdate") }}</li>
                        <li>{{ $t("expiry-date") }}</li>
                        <li>
                          {{
                            $t(
                              "a-visible-photo-e30b7286eb772633ab9e452d34ad29bd"
                            )
                          }}
                        </li>
                        <li>
                          {{
                            $t(
                              "all-four-corners-of-the-document-must-be-visible"
                            )
                          }}
                        </li>
                      </ul>
                    </div>
                    <div
                      v-else-if="item.name === 'Address Proof'"
                      class="text-sm leading-5 px-6 pt-5 pb-6 bg-gray-100 rounded-md"
                    >
                      <h3 class="text-sm leading-5 font-normal mb-5">
                        {{ $t("accepted-proof-of-address") }}
                      </h3>
                      <ul>
                        <li>
                          {{
                            $t(
                              "your-full-name-df1fc96e5401396fe01e24363a9ec40d"
                            )
                          }}
                        </li>
                        <li>{{ $t("Address") }}</li>
                        <li>{{ $t("issue-date-within-last-3-months") }}</li> <!-- BR#16 Mbeng Atemson changed issue date from 6 to 3 months 15/08/2024 -->
                        <li>
                          {{
                            $t(
                              "all-four-corners-of-the-document-must-be-visible"
                            )
                          }}
                        </li>
                      </ul>
                    </div>
                    <div
                      v-else-if="item.name === 'ApplicationForm'"
                      class="text-sm leading-5 px-6 pt-5 pb-6 bg-gray-100 rounded-md"
                    >
                      <h3 class="text-sm leading-5 font-normal mb-5">
                        {{
                          $t(
                            "documents-must-contain-all-of-the-following-details"
                          )
                        }}
                      </h3>
                      <ul>
                        <li>
                          {{
                            $t(
                              "your-full-name-df1fc96e5401396fe01e24363a9ec40d"
                            )
                          }}
                        </li>
                        <li>{{ $t("a-unique-id-number") }}</li>
                        <li>{{ $t("your-birthdate") }}</li>
                        <li>{{ $t("your-place-of-birth") }}</li>
                        <li>
                          {{
                            $t(
                              "a-visible-photo-e30b7286eb772633ab9e452d34ad29bd"
                            )
                          }}
                        </li>
                        <li>{{ $t("your-signature") }}</li>
                        <li>{{ $t("your-nationality") }}</li>
                      </ul>
                    </div>
                    <div
                      v-else-if="
                        whitelabel === 'Blackstone' &&
                        item.name === 'Proof of Identification'
                      "
                      class="text-sm leading-5 px-6 pt-5 pb-6 bg-gray-100 rounded-md"
                    >
                      <h3 class="text-sm leading-5 font-normal mb-5">
                        Documents must contain all of the following details:
                      </h3>
                      <ul>
                        <li>Your full name</li>
                        <li>A unique ID number</li>
                        <li>Your birth date</li>
                        <li>A visible photo</li>
                        <li>Your nationality</li>
                      </ul>
                    </div>
                    <div
                      v-else-if="
                        whitelabel === 'Blackstone' &&
                        item.name === 'Proof of Address'
                      "
                      class="text-sm leading-5 px-6 pt-5 pb-6 bg-gray-100 rounded-md"
                    >
                      <h3 class="text-sm leading-5 font-normal mb-5">
                        Documents must contain one of the following details:
                      </h3>
                      <ul>
                        <li>Utility bill</li>
                        <li>Bank Statement</li>
                        <li>Creditors Account e.g. Vodacom</li>
                        <li>Edgars</li>
                        <li>Affidavit</li>
                        <li>(No older than 3 months)</li>
                      </ul>
                    </div>
                    <div
                      v-else-if="
                        whitelabel === 'Blackstone' &&
                        item.name === 'Signed Risk Disclosure'
                      "
                      class="text-sm leading-5 px-6 pt-5 pb-6 bg-gray-100 rounded-md"
                    >
                      <h3 class="text-sm leading-5 font-normal mb-5">
                        Documents must contain all of the following details:
                      </h3>
                      <ul>
                        <li>Signed Document</li>
                      </ul>
                    </div>
                    <div
                      v-else-if="
                        whitelabel === 'Blackstone' &&
                        item.name === 'Proof of Bank Account'
                      "
                      class="text-sm leading-5 px-6 pt-5 pb-6 bg-gray-100 rounded-md"
                    >
                      <h3 class="text-sm leading-5 font-normal mb-5">
                        Documents must contain all of the following details:
                      </h3>
                      <ul>
                        <li>Your full name</li>
                        <li>Physical Address</li>
                        <li>Bank name</li>
                        <li>Account number</li>
                        <li>Account Particulars</li>
                        <li>(No older than 3 months)</li>
                      </ul>
                    </div>
                    <div
                      v-else-if="
                        whitelabel === 'Blackstone' &&
                        item.name === 'Voter Id (two sided)'
                      "
                      class="text-sm leading-5 px-6 pt-5 pb-6 bg-gray-100 rounded-md"
                    >
                      <h3 class="text-sm leading-5 font-normal mb-5">
                        Documents must contain all of the following details:
                      </h3>
                      <ul>
                        <li>Your full name</li>
                        <li>A unique ID number</li>
                        <li>Your birthdate</li>
                        <li>Your place of birth</li>
                        <li>A visible photo</li>
                        <li>Your signature</li>
                        <li>Your nationality</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Header from "@/components/Header";

export default {
  name: "UploadDocuments",
  components: {
    Header,
  },
  data() {
    return {
      file: "",
      file_two_side: [],
      errorSize: {
        value: false,
        message: "file-is-too-big-filesize-max-filesize-maxfilesize",
      },
      errorType: {
        value: false,
        message:
          "files-should-be-in-pdf-jpg-or-png-format-and-must-not-exceed-5mb",
      },
      whitelabel: process.env.VUE_APP_WHITELABEL,
    };
  },
  computed: {
    ...mapGetters([
      "get_upload_presets",
      "get_uploaded_documents",
      "get_profile",
    ]),
  },
  created() {
    this.$store.dispatch("set_upload_presets");
    this.$store.dispatch("set_uploaded_documents");
  },

  methods: {
    getNewAccessToken() {
      const userId = this.$store.getters.get_profile.id;
      const options = {
        method: "POST",
        headers: {
          "X-App-Token":
            "prd:3aWLD2H6pGNsiEGn5zB9ybRW.xCIqgyOzxC8pFNtqXfYRc3mFDMuHrhYK",
        },
      };

      console.log("userId", userId);
      const levelName = "basic-kyc-test";

      const api = `https://api.sumsub.com:80/resources/accessTokens?userId=${userId}&levelName=${levelName}?ttlInSecs=600`;
      return fetch(
        `https://api.sumsub.com/resources/accessTokens?userId=${userId}&levelName=basic-kyc-test&ttlInSecs=600`,
        options
      )
        .then((response) => response.json())
        .then((response) => console.log(response))
        .catch((err) => console.error(err));
    },
    launchWebSdk(accessToken, applicantEmail, applicantPhone) {
      if (this.whitelabel === "VCGMarkets") {
        console.log(
          "launchWebSdk",
          accessToken,
          applicantEmail,
          applicantPhone
        );
        let snsWebSdkInstance = snsWebSdk
          .init(accessToken, () => this.getNewAccessToken())
          .withConf({
            lang: "en",
            email: applicantEmail,
            phone: applicantPhone,
          })
          .withOptions({ addViewportTag: false, adaptIframeHeight: true })
          .on("idCheck.onStepCompleted", (payload) => {
            console.log("onStepCompleted", payload);
          })
          .on("idCheck.onError", (error) => {
            console.log("onError", error);
          })
          .onMessage((type, payload) => {
            console.log("onMessage", type, payload);
          })
          .build();
        snsWebSdkInstance.launch("#sumsub-websdk-container");
      }
    },
    sanitizeText(text) {
      return this.$sanitize(text);
    },
    onFileChanged(event, item, isDrop) {
      event.stopPropagation();
      event.preventDefault();
      let formData = new FormData();

      this.errorSize.value = false;
      this.errorType.value = false;
      // if is needed two side of document
      if (item.is_two_sided) {
        if (this.file_two_side.length < 2) {
          if (isDrop) {
            this.file_two_side.push(event.dataTransfer.files[0]);
            if (this.file_two_side.size > 5242880) {
              this.errorSize.value = true;
              return;
            }
            const validImageTypes = [
              "application/pdf",
              "image/jpeg",
              "image/png",
            ];
            if (!validImageTypes.includes(this.file.type)) {
              this.errorType.value = true;
              return;
            }
          } else {
            this.file_two_side.push(event.target.files[0]);
            if (this.file_two_side.size > 5242880) {
              this.errorSize.value = true;
              return;
            }
          }
          formData.append("document_type", item.name);
          formData.append("name", this.file_two_side[0].name);
          formData.append("file", this.file_two_side[0]);
          formData.append("file_backside", this.file_two_side[1]);

          // send data when both sides are uploaded
          if (this.file_two_side.length === 2) {
            this.$store
              .dispatch("upload_document", formData)
              .then((resp) => {
                this.$store.dispatch("set_uploaded_documents");
                this.$notify({
                  group: "foo",
                  text: `Please wait we are verifying your document`,
                  type: "success",
                });
              })
              .catch((err) => {
                // we need to clear the file_two_side after error, so the user can upload again without refreshing the page
                this.file_two_side = [];
                this.$notify({
                  group: "foo",
                  text: `${Object.values(err.data)[0][0]}`,
                  type: "warn",
                });
              });
          }
        }

        // when is needed only one side of document
      } else {
        if (isDrop) {
          this.file = event.dataTransfer.files[0];
          if (this.file.size > 5242880) {
            this.errorSize.value = true;
            return;
          }
          const validImageTypes = [
            "application/pdf",
            "image/jpeg",
            "image/png",
          ];
          // this.file.type should probably be event.dataTransfer.files[0] but it may return an array of values of all files, should test it out
          if (!validImageTypes.includes(this.file.type)) {
            this.errorType.value = true;
            return;
          }
        } else {
          this.file = event.target.files[0];
          //this.file.size should probably be event.target.files[0].size
          if (this.file.size > 5242880) {
            this.errorSize.value = true;
            return;
          }
        }
        formData.append("document_type", item.name);
        formData.append("name", this.file.name);
        formData.append("file", this.file);
        this.$store
          .dispatch("upload_document", formData)
          .then((resp) => {
            this.$store.dispatch("set_uploaded_documents");
            this.$notify({
              group: "foo",
              text: `Please wait we are verifying your document`,
              type: "success",
            });
          })
          .catch((err) => {
            this.file = "";
            this.$notify({
              group: "foo",
              text: `${err.data.file[0]}`,
              type: "warn",
            });
          });
      }
    },
    removeFile(id) {
      const id_file = this.get_uploaded_documents.results.find((document) => {
        if (document.document_type_id === id) {
          return document;
        }
      });
      this.$store
        .dispatch("delete_document", id_file.id)
        .then(() => {
          this.$store.dispatch("set_uploaded_documents");
          this.$notify({
            group: "foo",
            text: `Document removed successfully`,
            type: "success",
          });
        })
        .catch((err) => {
          this.$notify({
            group: "foo",
            text: `${Object.entries(err.response.data)[0][1]}`,
            type: "warn",
          });
        });
    },
  },
  mounted() {
    console.log("mounted");
    const profile = this.get_profile;
    console.log("profile", profile);
    this.launchWebSdk(
      "prd:3aWLD2H6pGNsiEGn5zB9ybRW.xCIqgyOzxC8pFNtqXfYRc3mFDMuHrhYK",
      profile?.email,
      profile?.address.telephone
    );
  },
};
</script>
